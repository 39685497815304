import React, { useState, useEffect, useRef  } from 'react';
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import axios from 'axios';
import ExportToExcel from '../../ExportToExcel';
import { MdFileUpload } from "react-icons/md";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

const groupBySessionId = (data) => {
  const sortedData = data.sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha));
  const grouped = sortedData.reduce((acc, item) => {
    if (!acc[item.sessionid]) {
      acc[item.sessionid] = [];
    }
    acc[item.sessionid].push(item);
    return acc;
  }, {});
  return grouped;
};

const ConversationsTable = ({ program_id,Expo }) => {

  // Estado para almacenar los datos del endpoint
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50, 
    total_pages: 1,
    total_records: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7); // Fecha inicial predeterminada: hace 7 días
    return date.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  });

  const [exportUrl, setExportUrl] = useState(() => `${Expo}?start_date=${startDate}&end_date=${endDate}`);
  const columnStyle = {
    display: 'block',
    maxHeight: '80px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    paddingTop: '11px',
    color: "#687079",
    fontWeight: "400"
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const params = {
        page: pagination.page,
        limit: pagination.limit
      };
      if (startDate) params.start_date = startDate;
      if (endDate) params.end_date = endDate;

      const response = await axios.get(program_id, { params });
      const fetchedData = response.data;
      setData(fetchedData.data); // Ahora la respuesta incluye data y paginación
      setPagination(prev => ({
        ...prev,
        total_pages: fetchedData.pagination.total_pages,
        total_records: fetchedData.pagination.total_records
      }));
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos', error);
      setError('Error al obtener los datos');
      setLoading(false);
    }
  };

  // Petición al endpoint usando useEffect
  useEffect(() => {
    loadData();
  }, [program_id, startDate, endDate, pagination.page]);

  
  // Manejando el estado de carga y errores
  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  function cleanMessage(message) {
    return message.replace(/【[^】]*】/g, '');
  }
// Agrupar los datos por sesión
const groupedData = groupBySessionId(data);
  return (
    <div className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Historial de Chats</h2>
      </div>
      <div className="container_graph">
        <div className="filtro">
          <div className='first'>
            <div className='container-input'>
              <txt>Fecha inicio</txt>
              <input
                type="date"
                value={startDate}
    
                onChange={(e) => {
                  setStartDate(e.target.value);
                  // min={maxDateString} Reiniciar la página al cambiar el filtro
                  setPagination(prev => ({ ...prev, page: 1 }));
                }}
                placeholder="Fecha inicial"
              />
            </div>
            <div className='container-input'>
              <txt>Fecha final</txt>
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  // Reiniciar la página al cambiar el filtro
                  setPagination(prev => ({ ...prev, page: 1 }));
                }}
                placeholder="Fecha final"
              />
            </div>
          </div>
        </div>
        <div className="table-containerT" >
          <table>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr style={{ fontSize: "clamp(12px, 1vw, 12px)" }}>
                <th>Chat</th>
                <th>Fecha</th>
                <th>Mensaje de</th>
                <th>Contenido</th>
                <th>Feedback (FB)</th>
                <th>Comentario FB</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(groupedData).map((sessionid) => {
                const sessionMessages = groupedData[sessionid];
                return sessionMessages.map((message, index) => (
                  <tr key={message.id}>
                    {index === 0 ? (
                      <td style={{ color: "#2B306E", fontWeight: "700" }} rowSpan={sessionMessages.length}>
                        {sessionid}
                      </td>
                    ) : null}
                    <td style={{ textAlign: "left", color: "#595959", fontWeight: "700" }}>
                      {(() => {
                        const messageDate = new Date(message.Fecha);
                        messageDate.setHours(messageDate.getHours() + 5);
                        return messageDate.toLocaleString("es-CO", {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: true
                        });
                      })()}
                    </td>
                    <td>
                      {message.type === 'user_message' ? (
                        <div className="content-center">
                          <IconoUser className="iconoUserSmall" />
                          <span style={{ fontWeight: 'bold', color: '#FF00B1' }}>Usuario</span>
                        </div>
                      ) : (
                        <div className="content-center">
                          <IconoBot className="iconoUserSmall" />
                          <span style={{ fontWeight: 'bold', color: '#2B306E' }}>Bot</span>
                        </div>
                      )}
                    </td>
                    <td style={columnStyle} dangerouslySetInnerHTML={{
                      __html: cleanMessage(message.content)
                        .replace(/<table/g, '<table style="border: 1px solid #595959; width: 100%; border-collapse: collapse;font-size: 80%;"')
                        .replace(/<thead/g, '<thead style="position: relative; background-color: white;"')
                        .replace(/<th/g, '<th style="border: 1px solid #595959; padding: 8px; text-align: left; background-color: white; position: relative"')
                        .replace(/<td/g, '<td style="border: 1px solid #595959; padding: 8px; text-align: left;"')
                    }} />
                    <td style={{ color: "#595959", fontWeight: "700" }}>{message.type !== 'user_message' ? message.Feedback : ''}</td>
                    <td style={columnStyle}>{message.type !== 'user_message' ? message.Comentario_Feedback : ''}</td>
                  </tr>
                ));
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <ExportToExcel Url={exportUrl} name="Historial_chatbot.xlsx"/>
          <div className="Base">
            <button 
              disabled={pagination.page <= 1}
              onClick={() => setPagination(prev => ({ ...prev, page: prev.page - 1 }))}
            >
              <BiChevronsLeft style={{ color: '#595959', fontSize: '110%' }}/>
            </button>
            <span style={{ textAlign: 'center', fontSize:'60%', color: '#595959'}}>{pagination.page} / {pagination.total_pages}</span>
            <button 
              disabled={pagination.page >= pagination.total_pages}
              onClick={() => setPagination(prev => ({ ...prev, page: prev.page + 1 }))}
            >
              <BiChevronsRight style={{ color: '#595959', fontSize: '110%' }}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsTable;
